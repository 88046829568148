import React from 'react'
import Typography from '@material-ui/core/Typography'
import Layout from '../layouts'
import HeaderNavbar from '../components/HeaderNavbar'
import Helmet from "react-helmet";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link} from "gatsby";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

export default () => {
    return (
        <Layout>
            <Helmet>
                <title>Contact Us | Signxperts</title>
            </Helmet>
            <HeaderNavbar/>
            <Container maxWidth='md'>
                <Box marginBottom={5} marginTop={3}>
                    <Breadcrumbs aria-label='breadcrumb'>
                        <Link color='inherit' to='/'>Home</Link>
                        <Typography color='textPrimary'>
                            Contact Us
                        </Typography>
                    </Breadcrumbs>
                </Box>
                <Typography variant={'h4'} gutterBottom>Contact Us</Typography>
                <Typography variant={'h6'}>Customer Service Hours</Typography>
                <Typography>Mon-Fri 8:00am - 5:00pm, ET</Typography>
                <br/>
                <Typography>Email: <a href="mailto:support@signxperts.com">support@signxperts.com</a></Typography>
                <Typography>Phone: <a href="tel:8606772497">(860) 677-2497 ext 312</a></Typography>
            </Container>
        </Layout>
    )
}
